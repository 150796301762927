/* eslint-disable @nx/enforce-module-boundaries */
import { Modal, ModalContent, ModalOverlay } from '@mybridge/ui/modal';
import { useState } from 'react';
import { DashboardPostContainer } from '../post-container';
import { useRouter } from 'next/router';
import { Text } from '@mybridge/ui';

export const DashboardPostModal = ({ postId, ...props }) => {
  const [expanded, setExpanded] = useState(false);
  const { back } = useRouter();
  return (
    <Modal
      size={expanded ? 'full' : '2xl'}
      {...props}
      motionPreset="slideInBottom"
      closeOnOverlayClick={true}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={expanded ? 0 : 10}
        // overflow="hidden"
        border="none"
        marginTop={!expanded ? `40px` : '0'}
        minW="750px"
        // maxH={"100vh"}
      >
        <DashboardPostContainer
          onClose={(e) => {
            back?.();
          }}
          postId={postId}
          setHasMedia={(m) => {
            setExpanded(m);
          }}
        />
      </ModalContent>
    </Modal>
  );
};
